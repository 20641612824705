<template>
  <div>
    <!-- 条件搜索 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <!-- <a-col :md="8" :sm="24">
            <a-form-item label="会员号">
              <a-input v-model="queryParams.name" placeholder="请输入会员号" allow-clear @pressEnter="handleQuery" />
            </a-form-item>
          </a-col> -->
          <a-col :md="8" :sm="24">
            <a-form-item label="申请分销身份">
              <a-select v-model="queryParams.distributorLevelId" placeholder="请选择身份">
                <a-select-option v-for="(item, index) in levelList" :key='item.level' :value='item.level'>{{ item.title
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParams.auditStatus" :options="statusArr" placeholder="请选择审核状态">
                <!-- <a-select-option v-for="(item, index) in statusArr" :key='item.level' :value='item.level'>{{ item.title
                }}</a-select-option> -->
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operations">
      <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns" :refresh-loading="loading"
        :pagination="false" @refresh="getList" />
    </div>
    <a-table :columns="columns" :data-source="data" rowKey="id" :pagination="false" bordered :size="tableSize">
      <span slot="levelName" slot-scope="text, record, index">{{ text }}</span>
      <span slot="auditStatus" slot-scope="text, record, index">{{ text | statusBycheck }}</span>
      <span slot="applyInfoName" slot-scope="text, record, index">
        <!-- 申请信息: {{ text }} -->
        <div v-for="item in record.applyInfoArr" :key="item.id" class="flex">
          <div style="width:100px" class="text-right">{{ item.fieldname }}：</div>
          <img :src="item.value" alt="" @click="handlePreview(item.value)" v-if="item.fieldtype === '4'" class="img" />
          <span v-else>{{ item.value }}</span>
        </div>
      </span>
      <template slot="action" slot-scope="text, record, index">
        <a-popconfirm title="确定要审核通过吗？" ok-text="确定" cancel-text="取消" @confirm="pass(record)">
          <a href="javascript:void(0)" v-if="record.auditStatus == 0">审核</a>
        </a-popconfirm>
        <a href="javascript:void(0)" v-if="record.auditStatus == 0" class="text-red margin-left-sm"
          @click="refuseModal(record)">拒绝</a>
      </template>
    </a-table>
    <!-- 分页 -->
    <a-pagination class="ant-table-pagination"   :current="queryParams.pageNum"
      :total="total" :page-size="queryParams.pageSize" :showTotal="total => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange" @change="changeSize" />

    <a-modal title="审核" :visible="visible" :confirm-loading="confirmLoading" @ok="refuse" @cancel="visible = false">
      <a-input v-model="auditOpinion" placeholder="填写审核意见" type="textarea" />
    </a-modal>
    <!-- 预览图片 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="审核图片" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import * as distribution from '@/api/distribution'
import { tableMixin } from '@/store/table-mixin'
export default {
  name: "apply",
  mixins: [tableMixin],
  props: {
    levelList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          title: '会员号',
          dataIndex: 'userId',
          key: 'userId'
        },
        {
          title: '申请分销身份',
          dataIndex: 'title',
          key: 'title',
          scopedSlots: { customRender: 'title' }
        },
        {
          title: '申请信息',
          dataIndex: 'applyInfoName',
          key: 'applyInfoName',
          scopedSlots: { customRender: 'applyInfoName' }
        },
        {
          title: '状态',
          dataIndex: 'auditStatus',
          key: 'auditStatus',
          width: 80,
          scopedSlots: { customRender: 'auditStatus' }
        }, {
          title: '驳回原因',
          dataIndex: 'auditOpinion',
          key: 'auditOpinion'
        },
        {
          title: '申请时间',
          dataIndex: 'createTime',
          key: 'createTime',
          scopedSlots: { customRender: 'createTime' }
        }, {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 120,
          // slots: { title: 'addbtn', customRender: 'name' },
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      total: 0,
      previewVisible: false,
      previewImage: "https://module.niurenjianzhan.com/img/logo.05eadbd7.png",
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        distributorLevelId: undefined,
        auditStatus: undefined
      },
      statusArr: [
        {
          id: 1,
          value: 0,
          label: '未审核',
        }, {
          id: 2,
          value: 1,
          label: '通过',
        }, {
          id: 3,
          value: 2,
          label: '拒绝',
        }
      ],
      auditOpinion: '',
      confirmLoading: false,
      visible: false,
      curInfo: {},
    };
  },
  mounted() {
    this.getList()
  },
  filters: {
    // auditStatus 	审核状态 0未审核 1通过 2拒绝
    statusBycheck(type) {
      let str = ''
      switch (type) {
        case 0: str = '未审核';
          break;
        case 1: str = '通过';
          break;
        case 2: str = '拒绝';
          break;
        default: str = ''
      }
      return str
    }
  },
  methods: {
    getList() {
      distribution.listBydistributor(this.queryParams).then(response => {
        if (response.success) {
          // this.data = response.data.records
          this.total = response.data.total;
          response.data.records.map((item) => {
            if (item.applyInfo) {
              let arr = JSON.parse(item.applyInfo)
              console.log('arr', arr)
              item.applyInfoArr = arr
              item.applyInfoName = arr.map((item) => {
                return item.fieldname + '：' + item.value
              }).join('；')
              this.levelList.map((item1) => {
                if (item.distributorLevelId == item1.level) {
                  item.levelName = item1.title
                }
              })
            }
          })
          console.log('1111', response.data.records)
          this.data = response.data.records
          this.$forceUpdate()
        }
      })
    },
    async handlePreview(url) {
      if (!url) return
      this.previewImage = url
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.distributorLevelId = undefined
      this.queryParams.auditStatus = undefined
      this.handleQuery();
    },
    changeFieldtype(e, record) {
      console.log(e, record)
      let value = e
      this.data.map((item, index) => {
        if (item.id == record.id) {
          this.$set(this.data[index], 'fieldtype', value)
        }
      })
      this.$forceUpdate()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParams.pageNum = current
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    pass(info, onOff) {
      distribution.checkApplication({
        id: info.id,
        auditStatus: 1,
      }).then(response => {
        if (response.success) {
          this.$message.success('操作成功')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    refuseModal(info) {
      this.visible = true
      this.curInfo = info
    },
    refuse(info, onOff) {
      this.confirmLoading = true
      distribution.checkApplication({
        id: this.curInfo.id,
        auditStatus: 2,
        auditOpinion: this.auditOpinion
      }).then(response => {
        this.visible = false
        this.confirmLoading = false
        if (response.success) {
          this.$message.success('操作成功')
          this.getList()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.visible = false
      })
    },
  }
}
</script>
<style scoped lang="less">
.img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
</style>